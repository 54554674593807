import {Stack} from "@mui/material";
import React from "react";
import {INNER_MAX_WIDTH} from "../../../App";
import {BoxWithFullWidthBackground} from "../../../components";
import {PersonsGrid} from "../peopleGrid/PersonsGrid";
import {TeamNavigationBarDesktopButton} from "./TeamNavigationBarDesktopButton";

export function TeamNavigationBarDesktop(props: {
	currentTab: "players" | "staff"
}) {
	const {currentTab} = props

	return (
		<Stack direction={"column"} sx={{
			width: "100%",
			maxWidth: {
				xs: "100%",
				md: INNER_MAX_WIDTH
			},
			pl: {
				xs: 0,
			},
			ml: {
				xl: "-32px"
			}
		}}>
			<BoxWithFullWidthBackground backgroundColor={"black"}>
				<Stack direction={"row"} sx={{width: "100%"}}>
					<TeamNavigationBarDesktopButton
						label={"Igralski kader"}
						selected={currentTab === "players"}
						to={"/team/players"}/>
					<TeamNavigationBarDesktopButton
						label={"Strokovni štab"}
						selected={currentTab === "staff"}
						to={"/team/staff"}/>
					{/*<TeamNavigationBarDesktopButton*/}
					{/*	label={"Vijol'čni bojevnik"}*/}
					{/*	selected={currentTab === "warrior"}*/}
					{/*	to={"/team/warrior"}/>*/}
				</Stack>
			</BoxWithFullWidthBackground>
			<PersonsGrid groups={[]}/>
		</Stack>
	)
}
