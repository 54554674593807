import {useIsMobileContext} from "@nitra/nkmb-card-components";
import Banner from "../../components/Banner";
import BannerFirebaseDocument from "../../models/firestore/common/BannerFirebaseDocument";

export function LandingTopBanner(props: { data?: BannerFirebaseDocument }) {
	const isMobile = useIsMobileContext();

	return (
		<Banner
			title={props.data?.title}
			imageUrl={(isMobile && props.data?.image720x540url) ? props.data?.image720x540url : props.data?.image1440x480url}
			createdAt={props.data?.createdAt}
			onClickLink={props.data?.actionLink}
			isForMatch={props.data?.isForMatch}
			desktopHeight={(isMobile && props.data?.image720x540url) ? 1080 : 480}
		/>
	)
}
